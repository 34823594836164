<template>
  <section id="hero">
    <v-parallax dark src="@/assets/bgHero2.png" height="750">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col cols="12" md="12" xl="12" class="text-center">
              <h1 class="hero-txt font-weight-bold mb-4">
                <!-- {{ $store.state.heroTxt }} -->
                SELF CAR WASH
              </h1>
              <h1 class="hero-subtxt fade-in-motto">
                <!-- {{ $store.state.motto }} -->
                Powered by NOVARTSOFT
              </h1>
              <v-btn
                rounded
                outlined
                large
                dark
                @click="$vuetify.goTo('#pricing')"
                class="mt-5"
              >
                Vezi pachetele
                <v-icon class="ml-2">mdi-arrow-right-bold-circle</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" xl="4" class="hidden-sm-and-down"> </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="svg-border-waves text-white">
        <v-img src="@/assets/img/borderWaves.svg" />
      </div>
    </v-parallax>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.animateMottos();
  },
  methods: {
    animateMottos() {
      let i = 0;
      setInterval(() => {
        if (i >= this.$store.state.mottos.length - 1) i = 0;
        else i++;
        this.$store.state.motto = this.$store.state.mottos[i];
      }, 4000);
    },
  },
};
</script>

<style>
@font-face {
  font-family: "Anton";
  src: url("@/assets/fonts/anton-webfont.woff") format("woff");
}
.fade-in-motto {
  animation: fadeIn 2s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hero-txt {
  font-family: Anton !important;
  font-size: 5vw !important;
  line-height: 4vh;
  letter-spacing: 0.35vw;
}
.hero-subtxt {
  font-family: Anton !important;
  font-size: 1rem !important;
  font-weight: 300 !important;
  line-height: 5vw;
  letter-spacing: 0.35vw;
}

@media screen and (max-width: 900px) {
  .hero-txt {
    font-family: Anton !important;
    font-size: 12vw !important;
    line-height: 8vh;
    letter-spacing: 0.7vw;
  }
  .hero-subtxt {
    font-family: Anton !important;
    font-size: 4vw !important;
    font-weight: 300 !important;
    letter-spacing: 0.35vw;
    padding-top: 30px;
  }
}

.circle {
  stroke: white;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0.3;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
section {
  position: relative;
}
</style>
