import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    /* HERO */
    heroTxt: "AUTOMATIZARI SELF CAR WASH",
    motto:
      "Revolutionizing car care: Effortless and eco-friendly washing, at your fingertips.",
    mottos: [
      "Revolutionizing car care: Effortless and eco-friendly washing, at your fingertips.",
      "Revolutionizing car care: Effortless washing through smart technology.",
      "Revolutionizing car care: Effortless washing with self-service technology.",
      "Streamlining car washing: Bringing convenience to your doorstep.",
    ],
    /* ABOUT US */
    features: [
      {
        icon: "mdi-phone",
        title: "Solicita oferta noastra",
        text: "Iti stam la dispozitie oricand pentru a discuta despre o oferta personalizata.",
      },
      {
        icon: "mdi-transit-transfer",
        title: "Aducem toate echipamentele",
        text: "Asiguram transportul si montajul pentru toate echipamentele ce vor fi montate.",
      },
      {
        icon: "mdi-car-wash",
        title: "Spalatorie SelfWash",
        text: "In cateva zile toate componentele vor fi montate si spalatoria va fi functionala.",
      },
    ],
    /* PRICING */
    products: [
      {
        title: "PLACA AUTOMATIZARE",
        image: "paperplane.svg",
        features: [
          { name: "Maxim 5 functii", included: true },
          { name: "Setari din telefon (conectare locala)", included: true },
          { name: "Suport cititor bani ICT-77", included: true },
          { name: "Multiple configuratii de ecran", included: true },
          { name: "Surse de curent necesare (12VDC,24VAC)", included: false },
          { name: "Mufe + Cabluri necesare", included: false },
          { name: "Butoane pornire/oprire functii", included: false },
          { name: "Buton oprire urgenta", included: false },
          { name: "Cititor de bani ICT-77 inclus", included: false },
          { name: "Sistem relee si contactoare pentru pompe", included: false },
          {
            name: "Niplu rotativ brat + niplu central self service",
            included: false,
          },
          {
            name: "Brat rotativ self service",
            included: false,
          },
          {
            name: "Clema presuri",
            included: false,
          },
          {
            name: "Pompa dozatoare ceara/spuma",
            included: false,
          },
          {
            name: "Electrovalve - 4 bucati",
            included: false,
          },
          {
            name: "Suport lance inox",
            included: false,
          },
          {
            name: "Kit spalare - pistol spalare + furtun",
            included: false,
          },
          {
            name: "Kit spumare - pistol spumare + furtun",
            included: false,
          },
          {
            name: "Suport pompa spalatorie fix",
            included: false,
          },
          {
            name: "Tevi + coturi + adaptoare",
            included: false,
          },
          {
            name: "Convertizor de frecventa",
            included: false,
          },
          {
            name: "Manopera",
            included: false,
          },
          {
            name: "Garantie 12 luni / boxa",
            included: false,
          },
        ],
        displays: [
          { name: "Display Digits", icon: "mdi-star", price: "+100€" },
          { name: 'Display TFT 3.5"', price: "+110€" },
          { name: 'Display TFT 4.3"', price: "+151€" },
          { name: 'Display TFT 7"', price: "+200€" },
        ],
        price: "450",
        sale: "20%",
        execution_time: "instant",
        hot: false,
      },
      {
        title: "PACHET AUTOMATIZARE",
        image: "airplane.svg",
        features: [
          { name: "Maxim 5 functii", included: true },
          { name: "Setari din telefon (conectare locala)", included: true },
          { name: "Suport cititor bani ICT-77", included: true },
          { name: "Multiple configuratii de ecran", included: true },
          { name: "Surse de curent necesare (12VDC,24VAC)", included: true },
          { name: "Mufe + Cabluri necesare", included: true },
          { name: "Butoane pornire/oprire functii", included: true },
          { name: "Buton oprire urgenta", included: true },
          { name: "Cititor de bani ICT-77 inclus", included: true },
          { name: "Sistem relee si contactoare pentru pompe", included: true },
          {
            name: "Niplu rotativ brat + niplu central self service",
            included: false,
          },
          {
            name: "Brat rotativ self service",
            included: false,
          },
          {
            name: "Clema presuri",
            included: false,
          },
          {
            name: "Pompa dozatoare ceara/spuma",
            included: false,
          },
          {
            name: "Electrovalve - 4 bucati",
            included: false,
          },
          {
            name: "Suport lance inox",
            included: false,
          },
          {
            name: "Kit spalare - pistol spalare + furtun",
            included: false,
          },
          {
            name: "Kit spumare - pistol spumare + furtun",
            included: false,
          },
          {
            name: "Suport pompa spalatorie fix",
            included: false,
          },
          {
            name: "Tevi + coturi + adaptoare",
            included: false,
          },
          {
            name: "Convertizor de frecventa",
            included: false,
          },
          {
            name: "Manopera",
            included: true,
          },
          {
            name: "Garantie 12 luni / pachet",
            included: true,
          },
        ],
        displays: [
          { name: "Display Digits", icon: "mdi-star", price: "inclus" },
          { name: 'Display TFT 3.5"', price: "+13€" },
          { name: 'Display TFT 4.3"', price: "+60€" },
          { name: 'Display TFT 7"', price: "+100€" },
        ],
        price: "3250",
        sale: "20%",
        execution_time: "o luna",
        hot: false,
      },
      {
        title: "SISTEM COMPLET PISTA",
        image: "aeroplane.svg",
        features: [
          { name: "Maxim 5 functii", included: true },
          { name: "Setari din telefon (conectare locala)", included: true },
          { name: "Suport cititor bani ICT-77", included: true },
          { name: "Multiple configuratii de ecran", included: true },
          { name: "Surse de curent necesare (12VDC,24VAC)", included: true },
          { name: "Mufe + Cabluri necesare", included: true },
          { name: "Butoane pornire/oprire functii", included: true },
          { name: "Buton oprire urgenta", included: true },
          { name: "Cititor de bani ICT-77 inclus", included: true },
          { name: "Sistem relee si contactoare pentru pompe", included: true },
          {
            name: "Niplu rotativ brat + niplu central self service",
            included: true,
          },
          {
            name: "Brat rotativ self service",
            included: true,
          },
          {
            name: "Clema presuri",
            included: true,
          },
          {
            name: "Pompa dozatoare ceara/spuma",
            included: true,
          },
          {
            name: "Electrovalve - 4 bucati",
            included: true,
          },
          {
            name: "Suport lance inox",
            included: true,
          },
          {
            name: "Kit spalare - pistol spalare + furtun",
            included: true,
          },
          {
            name: "Kit spumare - pistol spumare + furtun",
            included: true,
          },
          {
            name: "Suport pompa spalatorie fix",
            included: true,
          },
          {
            name: "Tevi + coturi + adaptoare",
            included: true,
          },
          {
            name: "Convertizor de frecventa",
            included: true,
          },
          {
            name: "Manopera",
            included: true,
          },
          {
            name: "Garantie 12 luni / boxa",
            included: true,
          },
        ],
        displays: [
          { name: "Display Digits", icon: "mdi-star", price: "inclus" },
          { name: 'Display TFT 3.5"', price: "+13€" },
          { name: 'Display TFT 4.3"', price: "+60€" },
          { name: 'Display TFT 7"', price: "+100€" },
        ],
        price: "8600",
        sale: "20%",
        execution_time: "1-2 luni",
        hot: true,
      },
      {
        title: "DISPERSOR LICHID PARBRIZ",
        image: "windshield.png",
        features: [
          { name: "Cutie inox cu print customizat", included: true },
          { name: "Setari din telefon (conectare locala)", included: true },
          { name: "Multiple configuratii de ecran", included: true },
          { name: "Surse de curent necesare (12VDC,24VAC)", included: true },
          { name: "Mufe + Cabluri necesare", included: true },
          { name: "Butoane pornire/oprire functii", included: true },
          { name: "Buton oprire urgenta", included: true },
          { name: "Cititor de bani ICT-77 inclus", included: true },
          { name: "Sistem relee si contactoare pentru pompe", included: true },
          { name: "Pompa apa + senzor debitmetru", included: true },
          {
            name: "Manopera",
            included: true,
          },
          {
            name: "Garantie 12 luni",
            included: true,
          },
        ],
        displays: [
          { name: "Display Digits", icon: "mdi-star", price: "inclus" },
          { name: 'Display TFT 3.5"', price: "+50€" },
          { name: 'Display TFT 4.3"', price: "+100€" },
          { name: 'Display TFT 7"', price: "+150€" },
        ],
        price: "2220",
        sale: "20%",
        execution_time: "o luna",
        hot: true,
      },
    ],
    /* CONTACT */
    contactText: "",
    companyDetails: [
      {
        justName: true,
        name: "S.C. NOVARTSOFT S.R.L<br/>Cod Unic de Înregistrare:47591531<br/>Nr. de înmatriculare:J26/226/2023<br/>",
        link: "",
        description: "",
        icon: "mdi-tel",
      },
      {
        justName: false,
        name: "+0040754815893",
        link: "tel:+0040754815893",
        description: "",
        icon: "mdi-phone",
      },
      {
        justName: false,
        name: "+0040755800468",
        link: "tel:+0040755800468",
        description: "",
        icon: "mdi-phone",
      },
      {
        justName: false,
        name: "contact@novartsoft.com",
        link: "mailto:contact@novartsoft.com",
        description: "",
        icon: "mdi-email",
      },
      {
        justName: false,
        name: "https://www.facebook.com/novartsoft",
        link: "https://www.facebook.com/novartsoft",
        description: "",
        icon: "mdi-facebook",
      },
      // {
      //   justName: false,
      //   name: "https://instagram.com/novartsoft",
      //   link: "https://instagram.com/novartsoft",
      //   description: "",
      //   icon: "mdi-instagram",
      // },
    ],
    /* FOOTER */
    footerContact: [
      {
        text: "mdi-facebook",
        link: "https://www.facebook.com/novartsoft",
      },
      {
        text: "mdi-instagram",
        link: "https://instagram.com/novartsoft",
      },
      {
        text: "mdi-email",
        link: "mailto:contact@novartsoft.com",
      },
      {
        text: "mdi-phone",
        link: "tel:0754815893",
      },
    ],
    footerDescription: "",
    footerCompany: "S.C. NOVARTSOFT S.R.L. All Rights Reserved.",
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
