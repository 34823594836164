<template>
  <v-card class="mx-auto my-3">
    <div class="flex-center pt-2">
      <div class="circle1">
        <div class="circle2">
          <v-img :src="require('../assets/img/' + product.image)"></v-img>
        </div>
      </div>
    </div>
    <v-card-title class="justify-center">
      {{ product.title }}
      <v-icon v-if="product.hot" color="error">mdi-fire</v-icon>
    </v-card-title>
    <v-card-text>
      <v-row align="center" class="mx-0"> </v-row>
      <div class="my-4 text-subtitle-1">
        <div
          class="text-uppercase blue--text caption text-start"
          v-for="(feature, indexFeature) in product.features"
          :key="'feature' + indexFeature"
        >
          <v-icon color="secondary" class="mr-2" v-if="feature.included">
            mdi-check-circle
          </v-icon>
          <v-icon color="#e0effc" class="mr-2" v-else>
            mdi-minus-circle
          </v-icon>
          {{ feature.name }}
        </div>
      </div>
      <div class="text-uppercase text-h4 mt-6 blue--text">
        {{ product.price }}<span class="text-h6">€</span>
      </div>
      <div>Timp executie: {{ product.execution_time }}</div>
    </v-card-text>
    <v-card-actions @click="show = !show">
      <v-btn color="secondary lighten-2" text> Afisaje disponibile </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>
          {{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-card-text>
          <v-list rounded dense>
            <v-list-item-group color="secondary">
              <v-list-item
                v-for="(display, displayIndex) in product.displays"
                :key="'displays-' + displayIndex"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ display.name }}
                    (
                    <span class="blue--text">
                      {{ display.price }}
                    </span>
                    )
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  props: {
    product: Object,
    index: Number,
  },
  data: () => ({
    show: false,
  }),
  computed: {
    size() {
      const size = { md: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
  },
};
</script>

<style lang="scss">
$main_color: #283e79;

ul {
  font-size: 13px;
  line-height: 1.5em;
  margin: 5px 0 15px;
  padding: 0;

  li {
    list-style: none;
    position: relative;
    padding: 0 0 0 20px;
  }

  li {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 5px;
      width: 10px;
      height: 10px;
      background-color: $main_color;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-eeborder-radius: 50%;
    }
  }
}
</style>

<style scoped>
.header {
  background-color: #283e79;
  color: white;
}

.circle1 {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  background-color: #f0f8ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle2 {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: #e0effc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg-border-rounded svg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #f4f7f5;
  z-index: -1;
}

#pricing {
  z-index: 0;
}

.content {
  z-index: 1;
}

svg {
  overflow: hidden;
}

section {
  position: relative;
}
</style>
