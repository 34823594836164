import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ro from "vuetify/lib/locale/ro";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#119DA4",
        secondary: "#171b34",
        accent: "3D87E4",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
  lang: {
    locales: { ro },
    current: "ro",
  },
});
