<template>
  <section id="about">
    <v-container fluid id="features" class="mt-2">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="space-around">
            <v-col cols="12" class="text-center">
              <h1 class="font-weight-bold display-2 secondary--text">
                Iti doresti o spalatorie selfwash ?
              </h1>
              <h1 class="font-weight-light">
                <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. -->
              </h1>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              class="text-center"
              v-for="(feature, i) in $store.state.features"
              :key="i"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-btn
                    x-large
                    fab
                    elevation="0"
                    color="secondary"
                    :class="{ 'zoom-efect': hover }"
                  >
                    <v-icon>{{ feature.icon }}</v-icon>
                  </v-btn>
                  <h1 class="font-weight-regular">{{ feature.title }}</h1>
                  <h4 class="font-weight-regular subtitle-1">
                    {{ feature.text }}
                  </h4>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="svg-border-waves">
      <img src="~@/assets/img/wave2.svg" />
    </div>
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col cols="12" md="7">
              <h1 class="font-weight-light display-2 secondary-text">
                Despre noi
              </h1>
              <h1 class="font-weight-light caption mb-3">
                S.C. NOVARTSOFT S.R.L
              </h1>
              <v-row>
                <v-col cols="12" class="d-flex align-center">
                  <p class="text-justify">
                    NOVARTSOFT este un brand romanesc creat acum 3 ani, care
                    produce si monteaza sisteme automatizate pentru spalatorii
                    self service la nivel national. Am creat softul pentru
                    automatizari cu multiple tipuri de ecran.Pot fi create
                    automatizari in functie de cerintele beneficiarului, cat si
                    in variantele de pachete propuse de noi. Oferta noastra
                    include proiectarea, dezvoltarea de software, fabricarea,
                    instalarea si punerea in functiune a instalatiei. Oferim
                    consultanta de specialitate in procesul de identificare a
                    solutiilor tehnice optime.
                  </p>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="align-center"> </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="5" class="d-none d-md-flex">
              <v-img
                src="@/assets/desprenoi.png"
                class="d-block ml-auto mr-auto"
                max-width="400px"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
#about {
  background-color: #f4f7f5;
}
</style>
