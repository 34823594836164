<template>
  <section class="pb-8" id="contact">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row justify="center">
            <v-col cols="12" sm="5">
              <h1 class="font-weight-light display-1">CONTACT</h1>
              <h3 class="font-weight-light mt-3">
                {{ $store.state.contactText }}
              </h3>
              <h3
                class="font-weight-light mt-3"
                v-for="(item, index) in $store.state.companyDetails"
                :key="index"
              >
                <a v-if="!item.justName" :href="item.link">
                  <v-icon>{{ item.icon }}</v-icon>
                  {{ item.name }}
                </a>
                <span v-if="item.justName" v-html="item.name"></span>
              </h3>
            </v-col>
            <v-col cols="12" sm="7">
              <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-text-field
                  v-model="name"
                  :rules="nameRules"
                  label="Nume"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                ></v-text-field>
                <v-textarea
                  v-model="textArea"
                  :rules="textAreaRules"
                  label="Mesaj"
                  required
                />
                <v-btn
                  :disabled="!valid"
                  color="primary"
                  :dark="valid"
                  rounded
                  block
                  class="mt-3"
                  @click="submit"
                >
                  Trimite
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="svg-border-waves text-white">
      <v-img src="~@/assets/img/borderWavesBlue.svg" />
    </div>
    <v-snackbar
      v-model="snackbar.enabled"
      timeout="3000"
      right
      top
      :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          v-bind="attrs"
          @click="snackbar.enabled = false"
          color="white"
        >
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<style scoped>
#contact {
  background-color: #f4f7f5;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}
</style>

<script>
// import {db} from '@/main'

export default {
  data: () => ({
    icons: ["fa-facebook", "fa-twitter", "fa-linkedin", "fa-instagram"],
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "Numele este obligatoriu!",
      (v) => (v && v.length >= 3) || "Minim 3 caractere!",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "Emailul este obligatoriu!",
      (v) => /.+@.+\..+/.test(v) || "Emailul nu este valid!",
    ],
    textArea: "",
    textAreaRules: [
      (v) => !!v || "Mesajul este obligatoriu!",
      (v) => (v && v.length >= 5) || "Minim 5 caractere!",
    ],
    lazy: false,
    snackbar: {
      enabled: false,
      text: "",
      color: "",
    },
  }),
  methods: {
    async submit() {
      let response = await fetch(
        process.env.VUE_APP_SERVER_URL + "/api/contact",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: this.name,
            email: this.email,
            message: this.textArea,
          }),
        }
      );
      response = await response.json();
      if (response.success) {
        this.snackbar.text =
          "Mesaj trimis cu success. Te vom contacta in cel mai scurt timp posibil.";
        this.snackbar.color = "success";
        this.snackbar.enabled = true;
      } else {
        this.snackbar.text = "Mesajul dnv nu s-a trimis, incercati din nou!";
        this.snackbar.color = "danger";
        this.snackbar.enabled = true;
      }

      /*db.collection("contactData").add({
        name: this.name,
        email: this.email,
        message: this.textArea
      }).then(() => {
        this.snackbar.text = "Mensagem enviada com sucesso"
        this.snackbar.color = "success"
        this.snackbar.enabled = true
      }).catch(() => {
        this.snackbar.text = "Erro ao enviar mensagem"
        this.snackbar.color = "danger"
        this.snackbar.enabled = true
      })*/
    },
  },
};
</script>
