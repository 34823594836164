<template>
  <v-footer dark padless>
    <v-card flat tile class="secondary white--text text-center">
      <v-card-text>
        <v-btn
          v-for="(icon, i) in $store.state.footerContact"
          :key="i"
          class="mx-4 white--text"
          :href="icon.link"
          target="_blank"
          icon
        >
          <v-icon size="24px">{{ icon.text }}</v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        {{ $store.state.footerDescription }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        &#169; {{ new Date().getFullYear() }} —
        <strong>{{ $store.state.footerCompany }}</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style scoped>
.v-card {
  width: 100%;
}
</style>

<script>
export default {
  name: "footer-component",
  data: () => ({}),
};
</script>
